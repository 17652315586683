import React from "react"
import { arrayOf, shape, string } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { Flex, Box } from "rebass"
import styled from "@emotion/styled"
import SearchItem from "./search-item"
import themeConfig from "../../config/theme"

const propTypes = {
  intl: shape().isRequired,
  query: string.isRequired,
  results: arrayOf(shape()).isRequired,
}

const Title = styled.h2`
  color: ${({ theme }) => theme.currentColours.fg};
  font-size: 110%;
  line-height: 1.1818181818181819em;
  letter-spacing: 0.1em;
  font-weight: 400;
  margin: 1em 0 2em;
  @media ${props => props.theme.breakpoint.desktop} {
    font-size: 70% !important;
  }
  span {
    font-weight: 600;
  }
`

const SearchResults = ({ intl, query, results }) => (
  <section aria-label={intl.formatMessage({ id: "search.search_results" })}>
    {!!results.length && query && (
      <Title
        className="search-results-count"
        id="search-results-count"
        aria-live="assertive"
      >
        {intl.formatMessage({ id: "search.found" })}{" "}
        <span>{results.length}</span>{" "}
        {intl.formatMessage({ id: "search.results" })}{" "}
        <span>&quot;{query}&quot;</span>
      </Title>
    )}

    {!!results.length && (
      <Flex mx={themeConfig.flexGutter} flexWrap="wrap">
        {results.map(({ title, url, slug }) => (
          <Box width={[1]} px={themeConfig.boxGutter} key={slug}>
            <SearchItem to={url} title={title} slug={slug} />
          </Box>
        ))}
      </Flex>
    )}
  </section>
)

SearchResults.propTypes = propTypes

export default injectIntl(SearchResults)
