import React from "react"
import { string, shape } from "prop-types"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import Heading2 from "../atoms/heading-2"

const propTypes = {
  title: string.isRequired,
  body: string,
  slug: string,
  brand: string,
  intl: shape().isRequired,
}

const defaultProps = {
  body: "",
  brand: "",
  slug: "",
}

const ItemTitle = styled(Heading2)``

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.currentColours.fg};
`

const SearchItem = ({ title, body, slug, brand, intl }) => {
  return (
    <StyledLink to={`${intl.formatMessage({ id: "product_path" })}/${slug}`}>
      <ItemTitle>{title}</ItemTitle>
    </StyledLink>
  )
}

SearchItem.propTypes = propTypes

SearchItem.defaultProps = defaultProps

export default injectIntl(SearchItem)
