/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { bool, shape } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import SearchForm from "./search-form"
import SearchResults from "./search-results"

const propTypes = {
  fieldOnly: bool,
  intl: shape().isRequired,
  location: shape().isRequired,
}

const defaultProps = {
  fieldOnly: false,
}

const SearchWrapper = ({ fieldOnly, location, intl }) => {
  const [results, setResults] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const { locale } = intl
  const query = location
    ? new URLSearchParams(location.search).get("s") || ""
    : ""
  useEffect(() => {
    setSearchQuery(searchQuery)
    if (searchQuery.length < 3) {
      return
    }
    if (window.__LUNR__) {
      window.__LUNR__.__loaded.then(lunr => {
        const refs = lunr[locale].index.search(searchQuery)
        const posts = refs.map(({ ref }) => lunr[locale].store[ref])
        setResults(posts)
      })
    }
  }, [locale, searchQuery])
  useEffect(() => {
    setSearchQuery(query)
    // if (searchQuery.length < 3) {
    //   return
    // }
  }, [locale, query])

  return (
    <div>
      <SearchForm
        setQuery={setSearchQuery}
        searchQuery={searchQuery}
        setSuggestions={setResults}
        locale={locale}
        location={location}
        fieldOnly={fieldOnly}
      />
      {!fieldOnly && <SearchResults query={searchQuery} results={results} />}
    </div>
  )
}

SearchWrapper.propTypes = propTypes
SearchWrapper.defaultProps = defaultProps

export default injectIntl(SearchWrapper)
