/* eslint-disable no-underscore-dangle */
import React from "react"
import { string, arrayOf, shape, func } from "prop-types"
import { Input } from "@rebass/forms"
import styled from "@emotion/styled"
import IconSearch from "../atoms/icon-search"
import InputText from "../atoms/input-text"

const propTypes = {
  searchQuery: string,
  setSuggestions: func.isRequired,
  locale: string.isRequired,
}

const defaultProps = {
  searchQuery: "",
}

const StyledForm = styled.form`
  position: relative;
  margin-bottom: 0;
`
const SearchFieldContainer = styled.div`
  position: relative;
`
const AutoCompleteList = styled.ul`
  background-color: ${({ theme }) => theme.currentColours.bg};
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  max-height: 200px;
  width: 100%;
`

const Submit = styled.button`
  position: absolute;
  top: 10px;
  right: 2px;
  padding: 0;
  margin: 0;
  background: none;
  width: auto;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

const SearchForm = ({
  setQuery,
  searchQuery,
  setSuggestions,
  locale,
  fieldOnly,
}) => {
  const handleSubmit = event => {
    if (fieldOnly) {
      return true
    }
    event.preventDefault()
  }
  const handleChange = formValue => {
    if (formValue.length < 3) {
      return
    }
    const query = `${formValue}`
    setQuery(query)
    if (window.__LUNR__) {
      window.__LUNR__.__loaded.then(lunr => {
        const refs = lunr[locale].index.search(query)
        const posts = refs.map(({ ref }) => lunr[locale].store[ref])
        setSuggestions(posts)
      })
    }
  }

  return (
    <StyledForm
      role="search"
      method="GET"
      action={`/${locale}/search`}
      onSubmit={handleSubmit}
    >
      <SearchFieldContainer>
        <InputText
          type="search"
          defaultValue={searchQuery}
          name="s"
          autocomplete="off"
          readonly
          onChange={event => {
            const { value } = event.target
            handleChange(value)
          }}
        />
      </SearchFieldContainer>
      <Submit type="submit">
        <IconSearch />
      </Submit>
    </StyledForm>
  )
}

SearchForm.propTypes = propTypes
SearchForm.defaultProps = defaultProps

export default SearchForm
