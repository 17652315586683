import React from "react"

const IconSearch = () => (
  <svg
    fill="none"
    height="19"
    viewBox="0 0 19 19"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="#000">
      <path d="m7.92601 14.5742c3.74839 0 6.78709-3.0387 6.78709-6.78711s-3.0387-6.78709-6.78709-6.78709c-3.7484 0-6.78709 3.03868-6.78709 6.78709s3.03869 6.78711 6.78709 6.78711z" />
      <path d="m18.4517 18.3128-5.7764-5.7764" />
    </g>
  </svg>
)

export default IconSearch
